import { render, staticRenderFns } from "./Fav.vue?vue&type=template&id=78715d6b&scoped=true"
import script from "./Fav.vue?vue&type=script&lang=js"
export * from "./Fav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78715d6b",
  null
  
)

export default component.exports